const contentVideoBtn = (btnSelectors) => {
    const btnPlay = document.querySelectorAll(btnSelectors);
    
    if(btnPlay.length>0){
        btnPlay.forEach((el)=>{
            el.addEventListener('click', function(e){
                e.preventDefault();

                let videoWrapper = el.closest('.js-video-wrapper');
                if(videoWrapper){
                    el.style.display = 'none';
                    let poster = videoWrapper.querySelector('.video-poster');
                    if(poster){
                        poster.style.display = 'none';
                    }

                    let overlay = videoWrapper.querySelector('.video-overlay');
                    if(overlay){
                        overlay.style.display = 'none';
                    }
                    
                    let video = videoWrapper.querySelector('video');
                    if(video){
                        video.play();
                        video.setAttribute('controls', true);
                        
                    }

                    let iframe = videoWrapper.querySelector('iframe');
                    if(iframe){
                        let src = iframe.getAttribute('data-src');
                        iframe.setAttribute('src', src+'?autoplay=1&controls=0');
                    }
                }
            });
        })
    }
}

const contentVideoAutoPlay = (parentSelector) => {
    const wrappers = document.querySelectorAll(`${parentSelector} .js-video-wrapper`);
    if(wrappers.length>0){
        wrappers.forEach((videoWrapper)=>{

            let poster = videoWrapper.querySelector('.video-poster');
            if(poster){
                poster.style.display = 'none';
            }

            let overlay = videoWrapper.querySelector('.video-overlay');
            if(overlay){
                overlay.style.display = 'none';
            }
            
            let video = videoWrapper.querySelector('video');
            if(video){
                video.play();
            }

            let iframe = videoWrapper.querySelector('iframe');
            if(iframe){
                let src = iframe.getAttribute('data-src');
                iframe.setAttribute('src', src+'?rel=0&autoplay=1&mute=1&controls=0');
            }
        })
    }
}

export { contentVideoAutoPlay }