import './_banner-slider.scss';
import Swiper from 'swiper';
import { Pagination, Navigation } from 'swiper/modules';
import 'swiper/css';
import { contentVideoAutoPlay } from 'JS/components/_video';

const bannerSlider = function() {
    const sectionWrapper = document.querySelectorAll('.js-banner-slider-wrapper');

    if (sectionWrapper.length > 0) {
        sectionWrapper.forEach(el => {
            let slider = el.querySelector('.js-swiper');

            if (slider) {
                Swiper.use([Pagination, Navigation]);
    
                new Swiper(slider, {
                    speed: 600,
                    updateOnWindowResize: true,
                    spaceBetween: 0,
                    slidesPerView: 1,
                    // pagination: {
                    //     el: el.querySelector('.swiper-pagination'),
                    // },
                    paginationClickable: true,
                    navigation: {
                        prevEl: el.querySelector('.swiper-button-prev'),
                        nextEl: el.querySelector('.swiper-button-next'),
                    },
                });
            }
        })
    }

    contentVideoAutoPlay('.js-banner-slider-wrapper');
};

document.addEventListener('DOMContentLoaded', function(){
    bannerSlider()
}, false);

window.addEventListener('acf/loaded/banner-slider', function (e) {
    bannerSlider()
});